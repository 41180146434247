<template>
  <form action="#" class="row breadcrumb-form" style="justify-content:space-between">
    <div v-if="false" class="col-3 d-none d-lg-block margin-workaround">
      <label>ORT</label>
      <destination-select @changedSelectedDestination="changeSelectedDestination" selectClass="form-control icon-right"/>
    </div>

    <div class="col-12 col-lg-4">
      <label>Datum</label>
      <date-selector @dateChanged="dateChanged" />
    </div>

    <div class="col-6 col-lg-3 margin-workaround">
      <label>UHRZEIT</label>
      <time-span-selector @changedSelectedTimeSpan="timeSpanChanged" :timeframe="timeframe"/>
    </div>

    <div class="col-6 col-lg-4 margin-workaround">
      <label>KATEGORIE</label>
      <div class="form-group">
        <select class="form-control" v-model="category" @change="selectCategory">
          <option value="" selected>Auswählen...</option>
          <option v-for="(category, index) in categories" :value="category.name" :key="index">{{ category.name }}</option>
        </select>
      </div>
    </div>

    <div v-if="false" class="col-6 col-lg-2 margin-workaround">
      <label>Suche</label>
      <keyword-search class="search" :isHome="true" :isEvents="true" />
    </div>

    <div class="row ml-auto reset-container">
      <div class="col-12 col-lg-2 margin-workaround">
        <span class="reset" @click="resetFilters">Filter zurücksetzen</span>
      </div>
    </div>
  </form>
</template>

<script>
  import { DatePicker } from 'v-calendar';

  export default {
    name: 'EventsBreadcrumbForm',
    components: {
      DatePicker,
      TimeSpanSelector: () => import('@/components/controls/TimeSpanSelector'),
      DestinationSelect: () => import('@/components/controls/DestinationSelect'),
      DateSelector: () => import('@/components/controls/DateSelector'),
      KeywordSearch: () => import('@/components/controls/KeywordSearch'),
    },
    props: {
      cat: {
        type: String,
      },
      timeframe: {
        type: String,
      },
    },
    watch: {
      cat: {
        handler: function(value) {
         this.category = this.cat;
       },
       immediate: true
     },
   },
   data: () => ({
    displayDate: false,
    category: "",
  }),
   computed: {
    categories(){
      const cats = this.$store.getters.getCategories;
      return cats.slice(0, -1);
    },
    readableTime() {
      return '6:00 AM'
    }
  },
  methods: {
    resetFilters(e){
      e.preventDefault()
      this.$emit('resetFilters')
    },
    timeSpanChanged(timeSpan){
      this.$emit('changedSelectedTimeSpan', timeSpan)
    },
    dateChanged(date){
      this.$emit('changedSelectedDate', date)
    },
    changeSelectedDestination(destination){
      this.$emit('changedSelectedDestination',destination);
    },
    intervalFormatter(locale, getOptions) {
     return locale.time;
   },
   selectCategory(event) {
    this.$emit('changedSelectedCategory',this.category);
  },
}
}
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .reset-container {


    .reset {
      font-size: 12px;
      white-space: nowrap;
      color: $primary;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: #ab2311;
      }
    }
  }

  .home-group{
    .home-input{
      border-radius: 0.25rem !important;
      height: auto;
    }
  }


</style>